<template>
	<div class="container">
		<div class="centered">
			<img src="../assets/bird-eats-bug-logo.svg" alt="Bird Eats Bug Logo" height="40" />
			<h1 class="title" style="margin-top: 32px">Welcome to the Bird Eats Bug code challenge 🎉</h1>
			<p style="margin-top: 20px">
				After pressing the start button, you will be invited to the Github repository, and have
				<strong>{{ allowedTime }} hours to submit a pull request</strong>. {{ solutionRatingTip }}
			</p>
			<p>Good luck!</p>
			<div style="margin-top: 32px">
				<button class="start-button" :class="{loading: isLoading}" @click="invite">Start</button>
			</div>
			<div class="error" v-for="error in errors" :key="error" style="margin-top: 20px">{{ error }}</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'

export default {
	props: {
		challenge: String,
		username: String,
	},
	computed: {
		allowedTime() {
			// We have 2h in the README and 2h might be more appropriate considering cloning, node version, runnning tests, etc.
			// if (this.challenge === 'node-code-challenge') return '1.5'
			return '2'
		},
		solutionRatingTip() {
			if (this.challenge === 'node-code-challenge') return ''
			return "We value quality over quantity, so don't feel pressured to complete all the tasks."
		},
	},
	data() {
		return {
			isLoading: false,
			errors: [],
		}
	},
	methods: {
		async invite() {
			this.isLoading = true
			this.errors = []
			try {
				const response = await axios.get('/api/invite', {
					params: {
						challenge: this.challenge,
						username: this.username,
					},
				})
				window.location.href = response.data.repositoryURL
			} catch (error) {
				this.errors = error.response.data.errors
				this.isLoading = false
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
}

.centered {
	max-width: 600px;
	padding: 30px;
}

.title {
	font-size: 16px;
	font-weight: 500;
}

.start-button {
	border: none;
	background-color: #00c57a;
	border-radius: 4px;
	color: white;
	padding: 6px 16px;
	font-weight: 500;
	cursor: pointer;
	font-size: 14px;
	outline: none;
}

.start-button.loading {
	cursor: auto;
	pointer-events: none;

	&::after {
		content: '';
		display: inline-block;
		width: 12px;
		height: 12px;
		background-image: url('../assets/load.svg');
		background-size: contain;
		vertical-align: -1px;
		margin-left: 8px;
		margin-right: -4px;
	}
}

.error {
	color: #ff6e64;
}
</style>
