import Vue from 'vue'
import VueRouter from 'vue-router'

import StartCodeChallenge from '../views/StartCodeChallenge.vue'
import Questions from '../views/Questions.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/:username/:challenge',
		name: 'StartCodeChallenge',
		component: StartCodeChallenge,
		props: true,
	},
	{
		path: '/questions',
		name: 'Questions',
		component: Questions,
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

export default router
